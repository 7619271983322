import React, { Component } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../layout"
import UserInfo from "../components/UserInfo"
import PostTags from "../components/PostTags"
import SEO from "../components/SEO"
import config from "../../data/SiteConfig"
import { formatDate, editOnGithub } from "../utils/global"
import NewsletterForm from "../components/NewsletterForm"
import kofi from "../../content/thumbnails/kofi.png"

export default class PostTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: false,
    }
  }

  render() {
    const { comments, error } = this.state
    const { slug } = this.props.pageContext
    const postNode = this.props.data.markdownRemark
    const post = postNode.frontmatter
    const popular = postNode.frontmatter.categories.find(
      category => category === "Popular"
    )
    let thumbnail

    if (!post.id) {
      post.id = slug
    }

    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID
    }

    if (post.thumbnail) {
      thumbnail = post.thumbnail.childImageSharp.fixed
    }

    const date = formatDate(post.date)
    const githubLink = editOnGithub(post)
    const twitterShare = `https://twitter.com/share?text=${encodeURIComponent(
      post.title
    )}&url=${config.siteUrl}/${post.slug}/&via=techstacker`

    return (
      <Layout>
        <Helmet>
          <title>{`${post.title} – ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <article className="single measure">
          <header
            className={`single-header ${!thumbnail ? "no-thumbnail" : ""}`}
          >
            {thumbnail && <Img fixed={post.thumbnail.childImageSharp.fixed} />}
            <div className="flex-column">
              <h1>{post.title}</h1>
              <div className="post-meta">
                <time className="date">{date}</time>

                {/*
                /
                <a
                  className="twitter-link"
                  href={twitterShare}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share
                </a>
                *}
              
{/*  /
                <a
                  className="github-link"
                  href={githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Edit ✏️
                </a>
*/}
              </div>
              <PostTags tags={post.tags} />
            </div>
          </header>

          <div
            className="post"
            dangerouslySetInnerHTML={{ __html: postNode.html }}
          />

          <div className="post-support-footer ">
            <hr />
            <h3>Has this been helpful to you?</h3>

            <p>
              You can support my work by sharing this article with others, or
              perhaps buy me a cup of coffee 😊
            </p>

            <a
              className="post-kofi-button"
              target="_blank"
              rel="noopener noreferrer"
              href="https://ko-fi.com/techstacker"
              aria-label="Buy me a coffee!"
              title="Buy me a coffee!"
            >
              <img src={kofi} alt="Kofi" className="kofi" />
            </a>

            <hr />

            <div className="flex flex-column justify-center flex-wrap">
              <h4 style={{ margin: "0" }}>Share & Discuss on</h4>
              <div style={{ padding: ".25rem" }}>
                <a href="javascript:window.location=%22https://twitter.com/share?url=%22+encodeURIComponent(document.location)+%22&amp;text=%22+encodeURIComponent(document.title)">
                  Twitter
                </a>
              </div>
              <div style={{ padding: ".25rem" }}>
                <a href="javascript:window.location=%22http://reddit.com/submit?url=%22+encodeURIComponent(document.location)+%22&amp;title=%22+encodeURIComponent(document.title)">
                  Reddit
                </a>
              </div>

              <div style={{ padding: ".25rem" }}>
                <a href="javascript:window.location=%22http://news.ycombinator.com/submitlink?u=%22+encodeURIComponent(document.location)+%22&t=%22+encodeURIComponent(document.title)">
                  Hacker News
                </a>
              </div>
            </div>
          </div>
        </article>

        {/*


        <div className="container no-comments">
          <h3>No comments?</h3>
          <p>
            There are intentionally no comments on this site. Enjoy! If you found any errors in this
            article, please feel free to{' '}
            <a className="github-link" href={githubLink} target="_blank" rel="noopener noreferrer">
              edit on GitHub
            </a>
            .
          </p>
        </div>
*/}
        <div className="container hide">
          <a
            className="button"
            href="https://techstacker.substack.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Subscribe to Newsletter
          </a>
        </div>
        {/*
        <UserInfo config={config} />
    */}
      </Layout>
    )
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        thumbnail {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        slug
        date
        categories
        tags
        template
      }
      fields {
        slug
        date
      }
    }
  }
`
